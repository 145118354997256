<template>
  <div class="user-profile--programs-list">
    <v-row>
      <v-col cols="12" sm="12" md="10" class="d-flex">
        <v-col cols="0" sm="0" lg="4" class="d-flex"> </v-col>
        <v-col cols="12" sm="6" md="6" lg="4" class="d-flex">
          <v-btn
            class="bg-primary-gradient primary ml-auto full-width"
            @click="regeneratePrograms"
            :disabled="loading || regenerating || programs.length == 0"
          >
            <v-icon
              class="mr-3"
              :class="{ 'animatedSpin-infinite': regenerating }"
            >
              {{ icons.refresh }}
            </v-icon>
            Refresh Program Validity
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="4" class="d-flex">
          <v-btn
            class="bg-primary-gradient primary ml-auto full-width"
            @click="addNewPrgoram = true"
          >
            <v-icon class="mr-3"> {{ icons.add }} </v-icon>
            Assign New Program
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10">
        <program-history-table
          :programs="programs"
          :loading="loading"
          @goTo="goTo"
        />
      </v-col>
    </v-row>

    <assign-new-program v-model="addNewPrgoram" @refresh-list="fetchPrograms" />
  </div>
</template>

<script>
import AssignNewProgram from '@/components/modals/users/AssignNewProgram'
import ProgramHistoryTable from '../components/ProgramHistoryTable'

import { mdiPlus, mdiAutorenew } from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'UserProgramList',

  components: {
    AssignNewProgram,
    ProgramHistoryTable,
  },

  data() {
    return {
      loading: false,
      regenerating: false,
      addNewPrgoram: false,
      icons: {
        add: mdiPlus,
        refresh: mdiAutorenew,
      },
    }
  },

  created() {
    this.fetchPrograms()
  },

  destroyed() {
    this.clearPrograms()
  },

  computed: {
    ...mapState({
      programs: (state) => state.userProfile.programs,
    }),
  },

  methods: {
    ...mapActions({
      getPrograms: 'userProfile/getPrograms',
      regenerateValidUntil: 'userProfile/regenerateValidUntil',
    }),
    ...mapMutations({ clearPrograms: 'userProfile/clearPrograms' }),

    async fetchPrograms() {
      this.loading = true

      await this.getPrograms(this.$route.params.id)

      await this.$sleep()

      this.loading = false
    },
    async regeneratePrograms() {
      this.loading = true
      this.regenerating = true
      await this.regenerateValidUntil(this.$route.params.id)
        .then(async () => {
          await this.getPrograms(this.$route.params.id)
        })
        .catch((err) => {
          this.$refs.errorToast.open({
            color: 'red',
            message: err,
          })
        })

      await this.$sleep()

      this.loading = false
      this.regenerating = false
    },
    goTo(programId) {
      this.$router.push({
        name: 'user.program-history.details',
        params: { programId },
      })
    },
  },
}
</script>
